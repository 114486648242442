import { GOOGLE_ANALYTICS_ID, APPLE_APP_ID } from "../config";

import * as React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import {
  Analytics,
  OpenGraph,
  Layout,
  ProductSection,
  VideoSection,
  InsideTheBoxSection,
  DinosSection,
  GallerySection,
  Footer,
} from "../components";

interface Image {
  childImageSharp: { fluid: { src: string; srcSet: string } };
}

interface Props {
  data: any;
  pageContext: { locale: string };

  meta_title: string;
  meta_description: string;
  meta_keywords: string;

  header_title: string;
  header_logo_alt: string;
  header_logo_icon: Image;
  header_navigation: {
    caption: string;
    link: string;
  }[];

  product_title: string;
  product_subtitle: string;
  product_image: Image;
  product_image_alt: string;
  product_description_part: string;

  features_icons: {
    caption: string;
    description: string;
    icon: { childImageSharp: { fluid: { src: string } } };
    alt: string;
  }[];

  items_title: string;
  items_image: Image;
  items_image_alt: string;
  items: {
    key: number;
    caption: string;
    description: string;
    image: { childImageSharp: { fluid: { src: string } } };
    alt: string;
  };

  dinos_title: string;
  dinos_image: {
    childImageSharp: {
      fluid: { originalImg: string; src: string; srcSet: string };
    };
  };
  dinos: {
    name: string;
    shortDescription: string;
    image: Image;
    alt: string;
    link: string;
  };

  gallery_title: string;
  gallery_subtitle: string;
  gallery_iphone_screenshots: {
    image: Image;
    small_image: { childImageSharp: { fluid: { src: string } } };
    alt: string;
  }[];
}

export const query = graphql`
  query($regex: String!) {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: $regex } }) {
      edges {
        node {
          id
          frontmatter {
            meta_title
            meta_description
            meta_keywords

            header_title
            header_logo_icon {
              childImageSharp {
                fluid {
                  src
                  srcSet
                }
              }
            }
            header_logo_alt
            header_navigation {
              caption
              link
            }

            product_title
            product_subtitle
            product_image {
              childImageSharp {
                fluid {
                  src
                  srcSet
                }
              }
            }
            product_image_alt
            product_description_part

            features_icons {
              caption
              description
              icon {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              alt
            }

            items_title
            items_image {
              childImageSharp {
                fluid {
                  src
                  srcSet
                }
              }
            }
            items_image_alt
            items {
              key
              caption
              description
              image {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              alt
            }

            dinos_title
            dinos_image {
              childImageSharp {
                fluid {
                  originalImg
                  src
                  srcSet
                }
              }
            }
            dinos {
              name
              shortDescription
              image {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                  }
                }
              }
              alt
              link
            }

            gallery_title
            gallery_subtitle
            gallery_iphone_screenshots {
              image {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                  }
                }
              }
              small_image {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              alt
            }

            footer_privacy
            footer_terms
          }
          excerpt
        }
      }
    }
  }
`;

class IndexPage extends React.Component<Props, {}> {
  public render() {
    // console.log(this.props.data);
    const { locale } = this.props.pageContext;
    return (
      <>
        <Analytics googleAnalyticsId={GOOGLE_ANALYTICS_ID} />
        {this.props.data.allMarkdownRemark.edges.map(({ node }: any) => (
          <div key={node.id}>
            <Helmet>
              <link rel="origin" href={`https://monsterparkbox.com`} />
              <link rel="canonical" href={`https://monsterparkbox.com`} />
              <title>{node.frontmatter.meta_title}</title>
            </Helmet>
            <OpenGraph
              title={node.frontmatter.meta_title}
              description={node.frontmatter.meta_description}
              appleAppId={APPLE_APP_ID}
            />
            <Layout
              locale={locale}
              meta_description={node.frontmatter.meta_description}
              meta_keywords={node.frontmatter.meta_keywords}
              header_title={node.frontmatter.header_title}
              header_logo_alt={node.frontmatter.header_logo_alt}
              header_logo_icon={
                node.frontmatter.header_logo_icon.childImageSharp.fluid.src
              }
              header_navigation={node.frontmatter.header_navigation}
            >
              <ProductSection
                title={node.frontmatter.product_title}
                subtitle={node.frontmatter.product_subtitle}
                product_image={
                  node.frontmatter.product_image.childImageSharp.fluid.src
                }
                product_image_alt={node.frontmatter.product_image_alt}
                description_part={node.frontmatter.product_description_part}
                features={node.frontmatter.features_icons}
              />
              <VideoSection />
              <InsideTheBoxSection
                items_title={node.frontmatter.items_title}
                items_image={node.frontmatter.items_image}
                items_image_alt={node.frontmatter.items_image_alt}
                items={node.frontmatter.items}
              />
              <DinosSection
                locale={locale}
                dinos_title={node.frontmatter.dinos_title}
                dinos_image={node.frontmatter.dinos_image}
                dinos={node.frontmatter.dinos}
              />
              <GallerySection
                gallery_title={node.frontmatter.gallery_title}
                gallery_subtitle={node.frontmatter.gallery_subtitle}
                gallery_iphone_screenshots={
                  node.frontmatter.gallery_iphone_screenshots
                }
              />
              <Footer
                footer_privacy={node.frontmatter.footer_privacy}
                footer_terms={node.frontmatter.footer_terms}
              />
            </Layout>
          </div>
        ))}
      </>
    );
  }
}

export default IndexPage;
